:focus {
  outline: none;
}

.btn-tool {
  margin: 5px 5px !important;
  text-align: center;
  width: 100%;
  transition: none !important;
  cursor: pointer !important;

  small {
    font-size: 16px;
  }
}

.btn-tool:hover {
  outline: none;
}

.dropdown button {
  width: 100% !important;
}

.dropdown-menu {
  width: 100% !important;
}

.dropdown-menu .dropdown-item {
  padding: 5px 1rem !important;
}

@media (max-width:768px) {
  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  a {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input[type=text],
  input[type=button] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

}