@include media-breakpoint-down(md) {
  .dropdown-menu.show{
    position: static !important;
    will-change: unset !important;
    top: 100% !important;
    left: 0px !important;
    transform: unset !important;
  }
}


.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height .35s ease;
       -o-transition: height .35s ease;
          transition: height .35s ease;
}
