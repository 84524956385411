@media (max-width: 768px) {
  .signinButton {
    margin: 10px auto !important;
    width: 100%;
  }

  .buttonPrevious,
  .buttonNext {
    margin-top: 5px;
    float: none;
    width: 100%;
  }

}

.filepond--root {
  margin-bottom: 0px !important;
}

.filepond--wrapper {
  border: 2px $primary dashed;
}