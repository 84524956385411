.cs-message-input {
    display: flex !important;
    height: auto !important;
}

@media (max-width: 768px) {
    .cs-conversation-header__back {
        display: flex !important;
    }

    .cs-sidebar.cs-sidebar--left {
        flex-basis: 100% !important;
        //min-width: 100%!important;
        display: block !important;
    }

    .cs-main-container--responsive .cs-conversation-list .cs-conversation__content,
    .cs-main-container--responsive .cs-sidebar .cs-conversation-list .cs-conversation__content {
        display: block !important;
        margin-left: 10px;
        min-width: 100% !important;
    }

    .cs-conversation__operations {
        -webkit-overflow-scrolling: touch;
        overflow-y: visible;
        display: block !important;
    }

    .chat-card {
        -webkit-overflow-scrolling: touch;
    }

}

.cs-sidebar.cs-sidebar--left {
    max-width: 100% !important;
}

@media (max-width: 300px) {
    .cs-main-container--responsive {
        min-width: 300px !important;
    }
}